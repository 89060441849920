import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

require('./LoaderV2.css')

export default class LoaderV2 extends PureComponent {
  static propTypes = {
    fixed: PropTypes.bool,
    spacy: PropTypes.bool,
    small: PropTypes.bool,
    inline: PropTypes.bool,
    children: PropTypes.node
  }

  render () {
    const { fixed, spacy, small, inline } = this.props

    const rootClasses = classNames('LoaderV2', {
      'is-small': small,
      'is-inline': inline,
      'is-fixed': fixed,
      'is-spacy': spacy
    })

    return (
      <div className={rootClasses}>
        <div className='LoaderV2-spinner'>
          <div className='LoaderV2-blade' />
          <div className='LoaderV2-blade' />
          <div className='LoaderV2-blade' />
          <div className='LoaderV2-blade' />
          <div className='LoaderV2-blade' />
          <div className='LoaderV2-blade' />
          <div className='LoaderV2-blade' />
          <div className='LoaderV2-blade' />
          <div className='LoaderV2-blade' />
          <div className='LoaderV2-blade' />
          <div className='LoaderV2-blade' />
          <div className='LoaderV2-blade' />
        </div>
        {this.props.children
          ? <span className='LoaderV2-text'>{this.props.children}</span>
          : null
        }
      </div>
    )
  }
}
