import React, { Component } from 'react'
import { FormField } from 'react-form'
import Select from 'react-select-me'

import './SelectField.css'

export default class SelectField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      options: props.options
    }
  }

  onSearch = (query) => {
    const searchQuery = query.toLowerCase()
    const filtered = this.props.options
      .filter(o => o.label.toLowerCase().includes(searchQuery))

    this.setState({
      options: filtered
    })
  }

  renderOption = (option, selectedOptions) => {
    const isSelected = option === selectedOptions[0]

    return (
      <div
        key={option.value}
        className={`dd__option ${isSelected ? 'dd__selectedOption' : ''}`}
        dangerouslySetInnerHTML={{ __html: option.label }}
      />
    )
  }

  selectedValueRenderer = (option, onRemove) => {
    return (
      <div key='selected' dangerouslySetInnerHTML={{ __html: option.label }} />
    )
  }

  render () {
    const { field, noItemsFound, placeholder } = this.props

    return (
      <FormField field={field}>
        {({ setValue, getValue, setTouched }) => {
          return (
            <div className='SelectField'>
              <Select
                searchable
                placeholder={placeholder}
                noItemsFound={noItemsFound}
                listMaxHeight={4 * 36}
                options={this.state.options}
                value={getValue()}
                onSearch={this.onSearch}
                optionRenderer={this.renderOption}
                selectedValueRenderer={this.selectedValueRenderer}
                onChange={item => setValue(item.value)}
              />
            </div>
          )
        }}
      </FormField>
    )
  }
}
