import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import { uid, database } from '../lib/firebase'
import logo from '../assets/logo.svg'
import background from '../assets/banner-lines.svg'
import { currencies } from '../const/currencies'
import InvestmentSummary from '../components/InvestmentSummary'
import { getDisplayCurrency } from '../selectors'
import './Header.css'

const currenciesOptions = currencies.map(c =>
  <option key={c.symbol} value={c.symbol}>{c.title}</option>
)

const onCurrencyChanged = (evt) => {
  evt.target.blur()
  const value = evt.target.value

  database
    .ref(`/settings/${uid()}/currency`)
    .set(value)
}

const Header = ({ currency, dispatch, onLogout }) => {

  return (
    <header className='SiteHeader'>
      <div className='SiteHeader_content'>

        <div className='SiteHeader_brand'>
          <img className='SiteHeader_brand_logo' src={logo} alt='' />
          <span className='SiteHeader_brand_name'>coinero</span>
        </div>

        <div className='SiteHeader_service'>
          <span>
            <select
              className='CurrencySelect'
              accessKey='c'
              value={currency}
              onChange={onCurrencyChanged}>
              {currenciesOptions}
            </select>
          </span>
          <span>
            <Link to='/app/asset' data-id='add-investment'>Add investment</Link>
          </span>
          <span>
            <a onClick={() => onLogout()}>Log out</a>
          </span>
        </div>

        <div className='SiteHeader_summary'>
          <InvestmentSummary />
        </div>

      </div>

      <img className='SiteHeader_bg' src={background} alt='' />

    </header>
  )
}

const mapStateToProps = (state) =>
({
  currency: getDisplayCurrency(state).code
})

export default connect(mapStateToProps)(Header)
