
import { createSelector } from 'reselect'

export const isAppLoading = (state) =>
  state.assets === null || state.rates === null || state.settings === null

export const getSettings = (state) => state.settings

export const getSyncSettings = createSelector(
  getSettings,
  (settings) => {
    return settings.sync
  }
)

export const getDisplayCurrency = (state) => {
  if (isAppLoading(state)) return null

  const currency = state.settings.currency

  return {
    code: currency,
    rate: state.rates[currency],
    toLocal: (price) => price * state.rates[currency],
    EUR: state.rates.EUR
  }
}

export const getInvestments = (state) => state.assets

export const getInvestmentsByCoin = (coin) => (state) => {
  if (!coin) return getInvestments(state)
  else return getInvestments(state).filter(inv => inv.coin === coin)
}

export const getCoinPrices = (state) => state.coins

export const getCoinData = (coin) => createSelector(
  getCoinPrices,
  (coinPrices) => {
    return coinPrices.get(coin)
  }
)

export const getCoinPrice = (coin) => createSelector(
  getCoinPrices,
  (coinPrices) => {
    const coinPrice = coinPrices.get(coin)

    if (!coinPrice) {
      console.error('Coin price not found for coin: ', coin)
      return null
    }

    return coinPrice.USD
  }
)

export const getRates = (state) => state.rates

export const getTotalInvestment = (coin) => createSelector(
  getDisplayCurrency,
  getInvestmentsByCoin(coin),
  getRates,
  getCoinData(coin),
  getCoinData('BTC'),
  (displayCurrency, investments, rates, coinData, coinDataBTC) => {
    return investments
      .filter(i => typeof i.price === 'number')
      .reduce((sum, investment) => {
        // if (investment.prices[currency] === undefined)
        //   throw new Error(`Investment does not have "prices.${currency}".`)

        // console.log(investment.price, investment.currency, ' ==> ', investment.prices[displayCurrency.code], displayCurrency.code)

        let price

        switch (investment.currency) {
          case 'EUR': {
            price = investment.price / rates.EUR
            break
          }
          case 'USD': {
            price = investment.price
            break
          }
          case 'BTC': {
            price = investment.price * investment.coin_price_usd
            break
          }
          default: {}
        }

        return sum + price
      }, 0)
  }
)

export const getTotalValue = (coin) => createSelector(
  getDisplayCurrency,
  getInvestmentsByCoin(coin),
  getRates,
  getCoinPrices,
  (displayCurrency, investments, rates, coinPrices) => {
    return investments
      .reduce((sum, investment) => {
        const coinPrice = coinPrices.get(investment.coin)

        if (!coinPrice) {
          console.error('Coin not found: ', investment.coin)
          return sum
        }

        return sum + (investment.amount * coinPrice.USD) //* rate)
      }, 0)
  }
)

export const getTotalProfit = (coin) => createSelector(
  getTotalInvestment(coin),
  getTotalValue(coin),
  (totalInvestment, totalValue) => {
    return totalValue - totalInvestment
  }
)

export const getTotalCoins = (coin) => createSelector(
  getInvestmentsByCoin(coin),
  (investments) => {
    return investments.reduce((sum, investment) => {
      return sum + investment.amount
    }, 0)
  }
)

/**
 * @returns {Map<string, object>}
 */
export const getGroupedInvestmentsByCoin = createSelector(
  getInvestments,
  (investments) => {
    return (investments || []).reduce((result, asset) => {
      if (!result.has(asset.coin)) result.set(asset.coin, [])

      result.set(
        asset.coin,
        [...result.get(asset.coin), asset]
      )

      return result
    }, new Map())
  }
)

export const getSortedInvestmentsByCoin = createSelector(
  isAppLoading,
  getGroupedInvestmentsByCoin,
  getSettings,
  getCoinPrices,
  (state) => state,
  (isLoading, investmentsByCoin, settings, coins, state) => {
    if (isLoading) return [] // TODO: fix loading

    const {
      coinOrderField = 'name',
      coinOrderDirection = 1
    } = settings

    return new Map([...investmentsByCoin].sort((a, b) => {
      let compared

      switch (coinOrderField) {
        case 'name': {
          const coinA = coins.get(a[0]) || {}
          const coinB = coins.get(b[0]) || {}

          compared = (coinA.title || a[0]).localeCompare(coinB.title || b[0])
          break
        }
        case 'profit': {
          compared = getTotalProfit(b[0])(state) - getTotalProfit(a[0])(state)
          break
        }
        default: {
          console.error('Invalid sort value:', coinOrderField)
        }
      }

      if (coinOrderDirection === -1) {
        return -compared
      }

      return compared
    }))

    // return investmentsByCoin
  }
)
