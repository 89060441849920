import React, { Component } from 'react'
import { connect } from 'react-redux'

import InvestmentsList from './InvestmentsList'
import { database, uid } from '../lib/firebase'
import {
  getCoinData,
  getDisplayCurrency,
  getInvestmentsByCoin,
  getCoinPrice
} from '../selectors'

class InvestmentsModal extends Component {
  constructor (props) {
    super(props)

    this.coin = props.params.coin
    this.state = {}

    console.log(props)
    // this.assetsRef = database.ref().child(`/assets/${uid()}`)
  }

  onEdit = (id) => {
    this.props.router.push({
      pathname: '/app/asset',
      query: { id, from: 'modal' }
    })
  }

  onDelete = (id) => {
    if (!window.confirm('Are you sure?')) return

    // this.assetsRef.child(id)
    database.ref().child(`assets/${uid()}/${id}`)
      .remove()
      .then(res => {
        // TODO: close only if all coins are deleted.
        // this.onClose()
      })
      .catch(err => {
        console.error(err)
      })
  }

  render () {
    const { coinData } = this.props
    const coinTitle = (coinData && coinData.title) ? coinData.title : this.coin

    // TODO: Replace H2 with <Dialog/> title.
    return (
      <div>
        <h2>{coinTitle} investments</h2>
        <InvestmentsList
          coin={this.coin}
          onEdit={this.onEdit}
          onDelete={this.onDelete}
        />
      </div>
    )
  }
}

// TODO: this is not being used?
const deleteAsset = (id) => {
  database.ref().child(`assets/${uid()}/${id}`)
    .remove()
    .then(res => {
      this.onClose()
    })
    .catch(err => {
      console.error(err)
    })

  return {
    type: 'DELETE_ASSET',
    payload: id
  }
}

const mapStateToProps = (state, props) => ({
  coinData: getCoinData(props.params.coin)(state),
  assets: getInvestmentsByCoin(props.params.coin)(state),
  coinPrice: getCoinPrice(props.params.coin)(state),
  displayCurrency: getDisplayCurrency(state)
})

const mapDispatchToProps = {
  deleteAsset
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestmentsModal)
