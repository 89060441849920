import React, { Component } from 'react'

import { callFunction } from '../lib/firebase'
import LoaderV2 from '../components/LoaderV2'

export default class AuthCallbackPage extends Component {
  componentDidMount () {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')

    // TODO: make callback function provider generic.
    callFunction('sync/coinbase/auth', { code }, { method: 'POST' })
      .then(res => {
        if (res.success) {
          window.close()
        } else {
          alert('Failed to authenticate with provider.')
          console.error(res)
        }
      })
      .catch(err => {
        alert('Unknown error while authenticating with provider.')
        console.error(err)
      })
  }

  render () {
    return (
      <LoaderV2 />
    )
  }
}
