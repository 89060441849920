
/*
AUD
BGN
BRL
CAD
CHF
CNY
CZK
DKK
EUR
GBP
HKD
HRK
HUF
IDR
ILS
INR
JPY
KRW
MXN
MYR
NOK
NZD
PHP
PLN
RON
RUB
SEK
SGD
THB
TRY
USD
ZAR
*/

export const currencies =
[
  { symbol: 'USD', title: 'USD' },
  { symbol: 'EUR', title: 'EURO' },
  { symbol: 'GBP', title: 'GBP' },
  { symbol: 'DKK', title: 'DKK' },
  { symbol: 'SEK', title: 'SEK' },
  { symbol: 'NOK', title: 'NOK' },
  { symbol: 'JPY', title: 'JPY' },
  { symbol: 'AUD', title: 'AUD' },
  { symbol: 'CAD', title: 'CAD' },
  { symbol: 'NZD', title: 'NZD' },
  { symbol: 'CHF', title: 'CHF' },
]

export const symbols =
{
  'USD': '$',
  'EUR': '€',
  'GBP': '£',
  'JPY': '¥',
  'AUD': 'A$',
  'NZD': 'NZ$',
}
