import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import logo from '../assets/logo.svg'

import './ContentPage.css'

export default class ContentPage extends Component
{
  render ()
  {
    return (
      <div className={`ContentPage ${this.props.className}`}>

        <header className='header container'>
          <Link to='/' className='brand'>
            <img width={32} height={32} className='logo' src={logo} alt='Logo' />
            <span className='name'>Coinero</span>
          </Link>
        </header>

        {this.props.children}

      </div>
    )
  }
}
