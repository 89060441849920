import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { auth } from './lib/firebase'
import { initTracking } from './lib/tracking'

class App extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    location: PropTypes.object,
    router: PropTypes.object,
    routes: PropTypes.array,
    children: PropTypes.element
  }

  state = {}

  componentDidMount () {
    const { dispatch, location } = this.props

    this.removeAuthListener = auth.onAuthStateChanged(user => {
      const { router } = this.props
      console.log('APP: onAuthStateChanged', user ? user.toJSON() : null)

      dispatch({ type: 'USER_STATE_CHANGE', payload: user ? user.toJSON() : null })

      if (process.env.NODE_ENV === 'production') {
        if (user) initTracking(user)
      }

      if (!user) {
        const route = this.props.routes.pop()
        if (route.requireAuth) {
          router.replace('/')
        }
      } else if (user && router.location.pathname === '/') {
        let next = null

        if (location.query && location.query.next) next = location.query.next
        else if (location.state && location.state.next) next = location.state.next

        router.replace(next || '/app')
      }

      this.setState({ ready: true })
    },
    (err) => {
      console.error('onAuthStateChanged error:', err)
    })
  }

  componentWillUnmount () {
    this.removeAuthListener()
  }

  render () {
    const { ready } = this.state

    return (
      <div className='App'>
        {ready && this.props.children}
      </div>
    )
  }
}

export default connect()(App)
