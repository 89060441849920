import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'
import { Container, Box } from 'breakbox'
import { Link } from 'react-router'

import Money from './Money'
import { applyRate, displayNumber } from '../utils'
import { displayCurrencyPropType } from '../const/prop-types'

import {
  getInvestmentsByCoin,
  getCoinPrice,
  getDisplayCurrency
} from '../selectors'

function InvestmentsList ({ coin, investments, displayCurrency, coinPrice, onEdit, onDelete }) {
  return [
    <table key='table' className='CoinAssets_table'>
      <thead>
        <tr>
          <th>Amount</th>
          <th>Worth</th>
          <th>Profit</th>
          <th>Purchased</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {investments.map(investment =>
          <InvestmentLine
            key={investment.id}
            investment={investment}
            coinPrice={coinPrice}
            displayCurrency={displayCurrency}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        )}
      </tbody>
    </table>,

    <Container key='add' paddingTop={2}>
      <Link to={'/app/asset?coin=' + coin} className='Button-alt' data-id='add-investment-coin'>
        Add new investment
      </Link>
    </Container>

  ]
}

InvestmentsList.propTypes = {
  coin: PropTypes.string.isRequired,
  investments: PropTypes.array.isRequired,
  coinPrice: PropTypes.number.isRequired,
  displayCurrency: displayCurrencyPropType,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

function InvestmentLine ({ investment, displayCurrency, coinPrice, onEdit, onDelete }) {
  const { id, amount, price, purchased } = investment
  const hasPrice = price !== null

  // let coinsValue_local
  // let profit_local
  // let paid_local

  // if (investment.currency === displayCurrency.code)
  // {
  //   paid_local = price
  //   coinsValue_local = amount * coinPrice[investment.currency]
  //   profit_local = coinsValue_local - price
  // }
  // else
  // {
  //   const euro_to_usd_rate = 0.85985

  //   const rate = investment.currency === 'EUR' ? euro_to_usd_rate : displayCurrency.rate
  //   coinsValue_local = amount * (coinPrice.USD * displayCurrency.rate)
  //   paid_local = (price / rate)
  //   profit_local = coinsValue_local - paid_local
  // }

  // console.log(investment.price, investment.currency, ' ### ', {
  //   coins_value: coinsValue_local,
  //   paid: paid_local,
  //   profit: profit_local
  // })

  // console.log(investment.currency, displayCurrency.code)

  const coinTotalValue_usd = coinPrice * amount
  const coinTotalValue_local = applyRate(coinTotalValue_usd, displayCurrency.rate)
  let currentProfit_local

  if (hasPrice) {
    let price_usd = investment.price

    switch (investment.currency) {
      case 'EUR': {
        price_usd = investment.price / displayCurrency.EUR
        break
      }

      case 'BTC': {
        price_usd = investment.price * investment.coin_price_usd
        break
      }

      default: {}
    }

    const currentProfit_usd = coinTotalValue_usd - price_usd
    currentProfit_local = applyRate(currentProfit_usd, displayCurrency.rate)
  }

  return (
    <tr>
      <td data-title='Amount'>
        {displayNumber(amount)}
        &nbsp;
        {investment.coin}
      </td>
      <td data-title='Worth'>
        <Money amount={coinTotalValue_local} currency={displayCurrency.code} />
      </td>
      <td data-title='Profit'>
        {hasPrice
          ? (
            <Money
              amount={currentProfit_local}
              currency={displayCurrency.code}
              withColors
              withSign
            />
          ) : (
            <span className='Muted'>N/A</span>
          )}
      </td>
      <td data-title='Purchased'>
        <span title={purchased}>{moment(purchased).fromNow()}</span>
      </td>
      <td data-title='Manage'>
        <a className='edit' onClick={() => onEdit(id)}>
          <svg width='14' height='14' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'>
            <path fill='currentColor' d='M0 9.5V12h2.5l7.373-7.373-2.5-2.5L0 9.5zm11.807-6.807c.26-.26.26-.68 0-.94l-1.56-1.56c-.26-.26-.68-.26-.94 0l-1.22 1.22 2.5 2.5 1.22-1.22z' />
          </svg>
        </a>
        &nbsp;
        <a className='delete' onClick={() => onDelete(id)}>
          <svg width='14' height='14' viewBox='0 0 10 12' xmlns='http://www.w3.org/2000/svg'>
            <path fill='currentColor' d='M1 10.667C1 11.4 1.6 12 2.333 12h5.334C8.4 12 9 11.4 9 10.667v-8H1v8zm8.667-10H7.333L6.667 0H3.333l-.666.667H.333V2h9.334V.667z' />
          </svg>
        </a>
      </td>
    </tr>
  )
}

InvestmentLine.propTypes = {
  investment: PropTypes.object.isRequired,
  coinPrice: PropTypes.number.isRequired,
  displayCurrency: displayCurrencyPropType,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

const mapStateToProps = (state, props) => ({
  investments: getInvestmentsByCoin(props.coin)(state),
  coinPrice: getCoinPrice(props.coin)(state),
  displayCurrency: getDisplayCurrency(state)
})

export default connect(mapStateToProps)(InvestmentsList)
