import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'
import firebase from 'firebase/app'

const config = {
  apiKey: 'AIzaSyDnZV4U0LbC8FjCrghMolKX3fGL8cwL2BI',
  authDomain: 'coinero-26ea9.firebaseapp.com',
  databaseURL: 'https://coinero-26ea9.firebaseio.com',
  projectId: 'coinero-26ea9',
  storageBucket: 'coinero-26ea9.appspot.com',
  messagingSenderId: '304817923202'
}

firebase.initializeApp(config)

const database = firebase.database()
const auth = firebase.auth()
const storage = firebase.storage()

window.__auth = auth

const getData = (path, asMap = false) => {
  return new Promise((resolve, reject) => {
    database.ref(path).once('value',
      (snapshot) => {
        if (!snapshot.hasChildren() || !asMap) { return resolve(snapshot.val()) }

        const data = new Map()
        snapshot.forEach(cs => {
          data.set(cs.key, cs.val())
        })
        resolve(data)
      },
      (err) => {
        reject(err)
      })
  })
}

const getUser = () => {
  let resolve
  let promise = new Promise(_resolve => { resolve = _resolve })

  const removeAuthListener = auth.onAuthStateChanged(user => {
    removeAuthListener()
    resolve(user ? user.toJSON() : null)
  })

  return promise
}

const uid = () => {
  if (!auth.currentUser) throw new Error('User is not authenticated.')
  return auth.currentUser.uid
}

const providerLogin = (providerId) => {
  if (providerId === 'facebook') {
    let provider = new firebase.auth.FacebookAuthProvider()
    provider.addScope('public_profile')
    provider.addScope('email')
    // provider.addScope('user_friends')
    // provider.addScope('user_location')
    // provider.addScope('user_birthday')

    return auth.signInWithPopup(provider)
  } else if (providerId === 'google') {
    let provider = new firebase.auth.GoogleAuthProvider()
    provider.addScope('profile')
    provider.addScope('email')

    return auth.signInWithPopup(provider)
  } else { throw new Error('Unknown provider: ' + providerId) }
}

const logOut = () => {
  return auth.signOut()
}

async function callFunction (functionUrl, body = null, fetchOptions = {}) {
  if (!process.env.REACT_APP_CLOUD_FUNCTIONS_URL) {
    throw new Error('REACT_APP_CLOUD_FUNCTIONS_URL is not defined.')
  }

  const firebaseToken = await auth.currentUser.getIdToken(true) // always refresh.
  const url = process.env.REACT_APP_CLOUD_FUNCTIONS_URL + '/' + functionUrl

  return fetch(url, {
    method: body ? 'POST' : 'GET',
    mode: 'cors',
    body: body ? JSON.stringify(body) : null,
    headers: new Headers({
      'Content-Type': 'application/json',
      'Firebase-Auth': firebaseToken
    }),
    ...fetchOptions
  })
    .then(res => res.json())
}

export {
  config,
  database,
  storage,
  auth,
  uid,
  providerLogin,
  logOut,
  getUser,
  getData,
  callFunction
}
