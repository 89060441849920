
export function initTracking (user) {
   try {
    window.Rollbar.configure({
      payload: {
        person: {
          id: user.uid,
          username: user.displayName,
          email: user.email
        }
      }
    })
  } catch (e) {}

  try {
    window.mixpanel.identify(user.uid)
    window.mixpanel.people.set({
      '$email': user.email,
      '$last_login': new Date(),
      'name': user.displayName,
      'provider':  user.providerData[0].providerId
    })
  } catch (e) {}
}
