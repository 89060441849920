import React from 'react'
import PropTypes from 'prop-types'

import { providerLogin } from '../lib/firebase'

const SocialLoginButtons = ({ loginText = 'Log in', onSuccess, onError }) =>
{
  const handleLogin = (providerId) =>
  {
    const ignoreCodes = [
      'auth/user-cancelled',
      'auth/popup-closed-by-user',
      'auth/cancelled-popup-request'
    ]

    providerLogin(providerId)
      .then(result =>
      {
        onSuccess(result.user)
      })
      .catch(err =>
      {
        console.error('Login error:', err)

        if (ignoreCodes.indexOf(err.code) !== -1)
          return

        onError(err)

        if (err.message)
          alert(err.message)
        else
          alert('Login failed. Try again.')
      })
  }

  return (
    <div className='LoginButtons'>

      <a className='SocialLoginButton is-facebook' onClick={() => handleLogin('facebook')} data-id='facebook-login-btn'>
        <svg className='SocialLoginButton-icon' xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 216 216">
          <path fill="#ffffff" d="M204.1 0H11.9C5.3 0 0 5.3 0 11.9v192.2c0 6.6 5.3 11.9 11.9 11.9h103.5v-83.6H87.2V99.8h28.1v-24c0-27.9 17-43.1 41.9-43.1 11.9 0 22.2.9 25.2 1.3v29.2h-17.3c-13.5 0-16.2 6.4-16.2 15.9v20.8h32.3l-4.2 32.6h-28V216h55c6.6 0 11.9-5.3 11.9-11.9V11.9C216 5.3 210.7 0 204.1 0z" />
        </svg>
        <span className='SocialLoginButton-text'>{loginText} with Facebook</span>
      </a>

      <a className='SocialLoginButton is-google' onClick={() => handleLogin('google')}>
        <svg className='SocialLoginButton-icon' xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
          <path fill="#FFF" fillRule="evenodd" d="M7 6v2.4h3.97c-.16 1.03-1.2 3.02-3.97 3.02-2.39 0-4.34-1.98-4.34-4.42S4.61 2.58 7 2.58c1.36 0 2.27.58 2.79 1.08l1.9-1.83C10.47.69 8.89 0 7 0 3.13 0 0 3.13 0 7s3.13 7 7 7c4.04 0 6.72-2.84 6.72-6.84 0-.46-.05-.81-.11-1.16H7zm15 0h-2V4h-2v2h-2v2h2v2h2V8h2"/>
        </svg>
        <span className='SocialLoginButton-text'>{loginText} with Google</span>
      </a>

    </div>
  )
}

SocialLoginButtons.defaultProps =
{
  onSuccess: () => {},
  onError: () => {}
}

SocialLoginButtons.propTypes =
{
  loginText: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
}

export default SocialLoginButtons
