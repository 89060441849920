import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import user from './user'
import coins from './coins'
import assets from './assets'
import settings from './settings'
import rates from './rates'

const appReducer = combineReducers({
  user,
  coins,
  assets,
  settings,
  rates,
  routing: routerReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    const { routing } = state
    state = { routing }
  }

  return appReducer(state, action)
}

export default rootReducer
