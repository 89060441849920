import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Container, FlexContainer, Box } from 'breakbox'

import LoaderV2 from '../LoaderV2'
import Status from '../Status'
import { uid, auth, database, callFunction } from '../../lib/firebase'

function pingProvider (provider)
{
  return callFunction(`sync/${provider}/ping`, null, { method: 'POST' })
    .then(response =>
    {
      if (response.success === true) return true
      return false
    })
    .catch(err =>
    {
      console.error('Ping failed:', err)
      return err
    })
}

function revokeProvider (provider)
{
  return callFunction(`sync/${provider}/auth`, null, { method: 'DELETE' })
    .then(response =>
    {
      if (response.success) {
        console.log('Revoke success.')
        return true
      }
      else
        return false
    })
    .catch(err =>
    {
      console.error('Revoke failed:', err)
      return err
    })
}

export function importTransaction (transaction)
{
  const key = database.ref('assets').push().key
  const postData =
  {
    uid: uid(),
    ref: transaction.ref,
    amount: transaction.amount,
    coin: transaction.coin,
    price: transaction.paid || null,
    currency: transaction.paid_currency || null,
    purchased: transaction.date || new Date().toISOString(),
    created: new Date().toISOString()
  }

  console.log('Import transaction data\n', JSON.stringify(postData, null, 2))

  return database.ref(`/assets/${uid()}/${key}`)
    .set(postData)
    .then(() =>
    {
      console.log('Transaction imported:', key, transaction.ref)
    })
    .catch(err =>
    {
      console.error('Failed to import transaction:', err)
      // alert('Failed to import transaction.')
    })
}

export default class Integration extends Component
{
  constructor ()
  {
    super()

    this.initialState = {
      open: false,
      transactions: null
    }

    this.state = this.initialState
  }

  static propTypes = {
    integration: PropTypes.object,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    provider: PropTypes.string.isRequired,
    isTransactionImported: PropTypes.func.isRequired,
    onEnable: PropTypes.func.isRequired,
    onRevoke: PropTypes.func,
  }

  isConfigured = () => !!this.props.integration

  loadTransactions = () =>
  {
    callFunction(`sync/${this.props.provider}/transactions`)
      .then(transactions =>
      {
        this.setState({ transactions })
      })
      .catch(err =>
      {
        console.error('Transactions loading failed:', err)
      })
  }

  renderTransactions = () =>
  {
    const {transactions} = this.state

    if (transactions === null) {
      return <LoaderV2 />
    }

    if (transactions.length === 0) {
      return (
        <div>
          No orders found.
        </div>
      )
    }

    return transactions.map(t =>
    {
      const isTransactionImported = this.props.isTransactionImported(t.ref)

      return (
        <Container key={t.ref} display='grid' marginBottom={1}>
          <h4>{t.title}</h4>
          <div>
            Bought {t.amount} {t.coin}
          </div>
          <div>
            For {t.paid} {t.paid_currency}
          </div>
          <time title={t.date}>{moment(t.date).fromNow()}</time>

          {isTransactionImported && (
            <button disabled>Already imported</button>
          )}
          {!isTransactionImported && (
            <button onClick={() => importTransaction(t)}>Import</button>
          )}

        </Container>
      )
    })
  }

  componentDidMount ()
  {
    if (this.isConfigured()) this.ping()
  }

  componentWillReceiveProps (nextProps)
  {
    if (!this.props.integration && nextProps.integration) {
      this.ping()
    }

    if (!nextProps.integration) {
      this.setState(this.initialState)
    }
  }

  ping = () =>
  {
    this.setState({ status: null })

    pingProvider(this.props.provider)
      .then(result =>
      {
        this.setState({ status: result })
      })
  }

  onRevoke = () =>
  {
    if (this.props.onRevoke) return this.props.onRevoke()

    revokeProvider(this.props.provider)
  }

  renderStatus = () =>
  {
    const {status} = this.state

    if (!this.props.integration) return null

    return (
      <div style={{
        display: 'flex',
        height: 16,
        width: 16,
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        {status === null && <LoaderV2 inline small />}
        {status === true && <Status good />}
        {status === false && <Status bad />}
      </div>
    )
  }

  toggleOpen = () =>
  {
    if (!this.state.open) {
      this.loadTransactions()
    }

    this.setState({ open: !this.state.open })
  }

  render ()
  {
    const {
      integration,
      title,
      subtitle,
      provider,
      children,
      onEnable
    } = this.props
    const { open, status } = this.state
    const enabled = !!integration

    return (
      <Container border='1px solid #efefef' padding={2}>

        <FlexContainer alignItems='center' justifyContent='space-around'>

          <Box flex={1}>
            <h3 style={{ margin: 0 }}>{title}</h3>
          </Box>

          {/*
          <Box marginRight={2}>
            {this.renderStatus()}
          </Box>
          */}

          <Box>
            {!enabled && (
              <button onClick={() => onEnable()}>Setup...</button>
            )}

            {enabled && status === null && (
              <LoaderV2 inline small />
            )}

            {enabled && status && (
              <button onClick={this.toggleOpen}>
                {open ? 'Hide transactions' : 'Show transactions'}
              </button>
            )}
          </Box>

        </FlexContainer>

        {children}

        {open && (
          <div>
            {this.renderTransactions()}
            {subtitle}
            <button onClick={this.onRevoke}>Remove integration</button>
          </div>
        )}

      </Container>
    )
  }
}