import React from 'react'
import { connect } from 'react-redux'

import { displayMoney } from '../utils'

import {
  getTotalProfit,
  getDisplayCurrency
} from '../selectors'

class TitleSummary extends React.Component
{
  constructor ()
  {
    super()
    this.originalTitle = document.title
  }

  updateTitle ()
  {
    const {totalProfit, displayCurrency} = this.props
    const totalProfit_local = displayCurrency.toLocal(totalProfit)

    document.title = `${displayMoney(totalProfit_local, displayCurrency.code, true)} \u205f—\u205f Coinero`
  }

  componentWillUnmount ()
  {
    document.title = this.originalTitle
  }

  render ()
  {
    this.updateTitle()
    return null
  }
}

const mapStateToProps = (state, props) =>
({
  displayCurrency: getDisplayCurrency(state),
  totalProfit: getTotalProfit(null)(state),
})

export default connect(mapStateToProps)(TitleSummary)
