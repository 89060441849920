import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import styled, { injectGlobal } from 'styled-components'

const Root = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.7);
`
injectGlobal`
  body.hasOverlay {
    overflow: hidden;
  }
`
const OverlayWrapper = styled.div`

`

class Overlay extends PureComponent {
  componentWillReceiveProps (nextProps) {
    this.toggleBodyClass(nextProps.visible)
  }

  componentWillMount () {
    this.toggleBodyClass(this.props.visible)
  }

  componentWillUnmount () {
    if (typeof document === 'undefined') return
    document.body.classList.remove('hasOverlay')
  }

  toggleBodyClass (state) {
    if (typeof document === 'undefined') return
    document.body.classList.toggle('hasOverlay', state)
  }

  render () {
    const { visible, ...rest } = this.props

    return (
      <OverlayWrapper>
        <ReactCSSTransitionGroup
          transitionAppear
          transitionAppearTimeout={400}
          transitionEnterTimeout={400}
          transitionLeaveTimeout={400}
          transitionName={{
            appear: 'FadeIn',
            appearActive: 'FadeIn_active',
            leave: 'FadeOut',
            leaveActive: 'FadeOut_active'
          }}
        >
          {visible && <Root {...rest} />}
        </ReactCSSTransitionGroup>
      </OverlayWrapper>
    )
  }
}

Overlay.propTypes = {
  visible: PropTypes.bool
}

export default Overlay
