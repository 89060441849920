import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'react-form'

import LoaderV2 from '../components/LoaderV2'
import SelectField from '../components/fields/SelectField'
// import ToggleField from '../components/fields/ToggleField'
import { uid, database } from '../lib/firebase'
// import { currencies } from '../const/currencies'
import { getSettings } from '../selectors'

// const currenciesOptions = currencies.map(c => ({ label: c.title, value: c.symbol }))

const COIN_SORT_ORDERS = [
  {
    value: 'name/1',
    label: 'Name (A-Z)'
  },
  {
    value: 'name/-1',
    label: 'Name (Z-A)'
  },
  {
    value: 'profit/1',
    label: 'Profit (Highest)'
  },
  {
    value: 'profit/-1',
    label: 'Profit (Lowest)'
  }
]

function SettingsView ({ settings, onClose }) {
  const getFormValues = (settings) => {
    const {
      coinOrderField,
      coinOrderDirection
    } = settings

    let coinOrder = null

    if (coinOrderField) {
      coinOrder = `${coinOrderField}/${coinOrderDirection}`
    }

    return {
      coinOrder: coinOrder || 'name/1'
    }
  }

  const onSubmit = (values) => {
    console.log('Form submit:', values)

    let [coinOrderField, coinOrderDirection] = values.coinOrder.split('/')

    const payload = {
      coinOrderField,
      coinOrderDirection: parseInt(coinOrderDirection)
    }

    database.ref(`settings/${uid()}`)
      .update(payload)
      .then(() => {
        onClose()
      })
  }

  if (!settings) {
    return (
      <LoaderV2 spacy />
    )
  }

  return (
    <div>

      <Form
        onSubmit={values => onSubmit(values)}
        // validate={this.onValidate}
        defaultValues={getFormValues(settings)}
      >
        {({ submitForm, values, getError }) => {
          return (
            <form onSubmit={submitForm}>

              <div className='FormRow'>
                <label>
                  Coin order:
                </label>
                <SelectField field='coinOrder' options={COIN_SORT_ORDERS} />
              </div>

              {/* <div className='FormRow'>
                <label>
                  Receive weekly portfolio status:
                </label>
                <ToggleField field='weekly_email' />
              </div>

              <div className='FormRow'>
                <label>
                  Email currency
                </label>
                <Select field='email_currency' options={currenciesOptions} showErrors={false} />
                <p>The currency you want to your email to have.</p>
              </div> */}

              <button className='Button' type='submit'>Save</button>

            </form>
          )
        }}
      </Form>

    </div>
  )
}

const mapStateToProps = (state) => ({
  settings: getSettings(state)
})

export default connect(mapStateToProps)(SettingsView)
