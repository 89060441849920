import React from 'react'

const Status = ({ good, bad }) =>
  <div style={{
    display: 'inline-block',
    backgroundColor: (good && '#00C27E') || (bad && '#FF0034') || '#737F8E',
    borderRadius: '50%',
    width: 9,
    height: 9
   }} />

Status.displayName = 'Status'

export default Status

