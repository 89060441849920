import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { openPopup } from '../../utils'
import Integration from './Integration'

function getProviderAuthUrl (provider) {
  return `${process.env.REACT_APP_CLOUD_FUNCTIONS_URL}/sync/${provider}/auth`
}

export default class OAuthExchangeIntegration extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  static propTypes = {
    integration: PropTypes.object,
    title: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    isTransactionImported: PropTypes.func.isRequired,
    popupSize: PropTypes.array
  }

  enableIntegration = () => {
    const authUrl = getProviderAuthUrl(this.props.provider)
    console.log({ authUrl })
    openPopup(authUrl, ...this.props.popupSize)
  }

  render () {
    return (
      <div>

        <Integration
          onEnable={this.enableIntegration}
          {...this.props} />

      </div>
    )
  }
}
