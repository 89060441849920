import React from 'react'
import PropTypes from 'prop-types'

import RegisterFormContainer from '../components/RegisterForm'
import SocialLoginButtons from '../components/SocialLoginButtons'

export default class SignupPage extends React.Component
{
  static propTypes =
  {
    router: PropTypes.object
  }

  onSuccess = () =>
  {
    this.props.router.push({ pathname: '/app', query: { from: 'signup' } })
  }

  render ()
  {
    return (
      <div className='narrow-container'>
        <h1 className='page-title'>Sign up</h1>
        <p className='lead'>Start tracking your investments today</p>

        <SocialLoginButtons loginText='Sign up' onSuccess={this.onSuccess} />

        <h2 className='email-form-header'>Sign up with email</h2>

        <RegisterFormContainer {...this.props} onSuccess={this.onSuccess} />

      </div>
    )
  }
}
