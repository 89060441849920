import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { Container, Box } from 'breakbox'

import CoinLogo from './CoinLogo'
import Menu from './Menu'
import MoreButton from './MoreButton'
import Money from './Money'
import Percentage from './Percentage'
import {
  diff,
  displayNumberSmart
} from '../utils'

import {
  getInvestmentsByCoin,
  getTotalInvestment,
  getTotalValue,
  getTotalProfit,
  getDisplayCurrency,
  getCoinPrice,
  getCoinData,
  getTotalCoins
} from '../selectors'

function CoinInvestment ({
  coin,
  coinPrice,
  coinData,
  totalCoins,
  totalInvestment,
  totalValue,
  totalProfit,
  investments,
  displayCurrency
}) {
  const coinTitle = coinData ? coinData.title : coin
  const profitPercentage = diff(totalInvestment, totalValue)

  let priceLastUpdated = coinData.updated

  try {
    const priceLastUpdatedDate = new Date(coinData.updated)
    const rtf1 = new Intl.RelativeTimeFormat('en', { style: 'narrow' });
    priceLastUpdated = rtf1.format(priceLastUpdatedDate, 'hour')
  } catch (err) {
  }

  const hasPrices = investments.some(inv => inv.price !== null)

  return (
    <Container className='CoinInvestment'>

      <Container
        className='header'
        margin={[1]}
        marginBottom={2}
        display='flex'
        alignItems={['', 'center']}
        flexFlow={['column', 'row']}
        justifyContent={['', 'space-between']}>

        <Container display='flex' flexFlow='row' alignItems='center'>
          <Box marginRight={[2]}>
            <CoinLogo coin={coin} />
          </Box>
          <Box marginRight={[2]} className='title'>
            {coinTitle}
          </Box>
          <Box className='Muted' marginRight={['auto', 2]}>
            {displayNumberSmart(totalCoins)} {coin}
          </Box>
          <Box flexGrow='0'>
            <Menu button={<MoreButton />}>
              <Link to={`/app/investments/${coin}`}>Edit investments</Link>
              <Link to={`/app/asset?coin=${coin}`}>Add investment</Link>
            </Menu>
          </Box>

        </Container>

        <Box className='Muted' marginLeft={['', 'auto']}>
          1 {coin}
          {' '}={' '}
          <span title={priceLastUpdated}>
            {displayNumberSmart(displayCurrency.toLocal(coinPrice))} {displayCurrency.code}
          </span>
        </Box>

      </Container>

      <Container
        display='grid'
        gridTemplateColumns={['', '1fr 1fr 1fr']}
        gridGap={[2]}>

        <Box className='ValueBox'>
          <div className='label'>Profit</div>
          <div className='value'>
            {hasPrices ? (
              <div>
                <Money
                  amount={displayCurrency.toLocal(totalProfit)}
                  currency={displayCurrency.code}
                  withSign
                  withColors />
                <Percentage value={profitPercentage} sign />
              </div>
            ) : (
              <span className='Muted'>N/A</span>
            )}
          </div>
        </Box>
        <Box className='ValueBox'>
          <div className='label'>Investment</div>
          <div className='value'>
            {hasPrices ? (
              <Money
                amount={displayCurrency.toLocal(totalInvestment)}
                currency={displayCurrency.code}
                className='CoinAssets_details_value' />
            ) : (
              <span className='Muted'>N/A</span>
            )}
          </div>
        </Box>
        <Box className='ValueBox'>
          <div className='label'>Net worth</div>
          <div className='value'>
            <Money
              amount={displayCurrency.toLocal(totalValue)}
              currency={displayCurrency.code}
              className='CoinAssets_details_value' />
          </div>
        </Box>
      </Container>

    </Container>
  )
}

CoinInvestment.propTypes = {
  coin: PropTypes.string.isRequired
}

const mapStateToProps = (state, props) => ({
  investments: getInvestmentsByCoin(props.coin)(state),
  totalInvestment: getTotalInvestment(props.coin)(state),
  totalValue: getTotalValue(props.coin)(state),
  totalProfit: getTotalProfit(props.coin)(state),
  displayCurrency: getDisplayCurrency(state),
  coinPrice: getCoinPrice(props.coin)(state),
  coinData: getCoinData(props.coin)(state),
  totalCoins: getTotalCoins(props.coin)(state)
})

export default connect(mapStateToProps)(CoinInvestment)
