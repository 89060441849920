import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Text, Select, Textarea } from 'react-form'

import { callFunction } from '../../lib/firebase'
import Integration from './Integration'

export default class KeysExchangeIntegration extends Component {
  constructor () {
    super()

    this.state = {
      loading: false,
      showKeysForm: false
    }
  }

  static propTypes = {
    integration: PropTypes.object,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    provider: PropTypes.string.isRequired,
    isTransactionImported: PropTypes.func.isRequired
  }

  onKeysSubmit = async (values) => {
    const { apiKey, secretKey } = values

    this.setState({ loading: true })

    try {
      const result = await callFunction(`sync/${this.props.provider}/auth`, { apiKey, secretKey })
    } catch (err) {
      console.error(err)
    }

    this.setState({ loading: false })
  }

  renderKeysForm = () => {
    const { loading } = this.state

    return (
      <Form onSubmit={values => this.onKeysSubmit(values)}>
        {({ submitForm, values, getError }) => {
          return (
            <form onSubmit={submitForm}>

              <div className='FormRow'>
                <label>Api key</label>
                <Text field='apiKey' required />
              </div>

              <div className='FormRow'>
                <label>Secret key</label>
                <Text field='secretKey' required />
              </div>

              {/* TODO: check loading disabled works. */}
              <button className='Button' type='submit' disabled={loading}>
                {
                  loading
                    ? 'Please wait...'
                    : 'Enable integration'
                }
              </button>

            </form>
          )
        }}
      </Form>
    )
  }

  showKeysForm = () => {
    this.setState({ showKeysForm: true })
  }

  componentWillReceiveProps (nextProps) {
    if (!this.props.integration && nextProps.integration) {
      this.setState({ showKeysForm: false })
    }
  }

  render () {
    const { showKeysForm } = this.state

    return (
      <div>

        <Integration
          onEnable={this.showKeysForm}
          {...this.props}
        >
          {showKeysForm && this.renderKeysForm()}
        </Integration>

      </div>
    )
  }
}
