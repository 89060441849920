import React from 'react'
import PropTypes from 'prop-types'

const CoinLogo = ({ symbol, coin, width = 32, height = 32 }) => (
  <div className='CoinLogo' style={{ width, height }}>
    <img
      width={width}
      height={width}
      className='img'
      src={`/assets/coins/${coin || symbol}.svg`}
      onError={e => e.target.parentNode.classList.add('has-error')}
    />
  </div>
)

CoinLogo.propTypes = {
  coin: PropTypes.string,
  symbol: PropTypes.string, // TODO: remove symbol.
  width: PropTypes.number,
  height: PropTypes.number
}

export default CoinLogo
