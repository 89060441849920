
export default function user (state = null, action) {
  const { type, payload } = action

  switch (type) {
    case 'ASSETS_UPDATE':
      return payload

    default:
      return state
  }
}
