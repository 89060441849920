import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Loader.css'

export default class Loader extends PureComponent {
  static propTypes = {
    fixed: PropTypes.bool,
    spacy: PropTypes.bool,
    children: PropTypes.node
  }

  render () {
    let bars = []
    const { fixed, spacy } = this.props

    for (let i = 0; i < 12; i++) {
      let barStyle =
      {
        animationDelay: (i - 12) / 10 + 's',
        transform: 'rotate(' + (i * 30) + 'deg) translate(146%)'
      }

      bars.push(
        <span key={i} style={barStyle} className='react-spinner_bar' />
      )
    }

    const rootClasses = classNames('Loader',
      {
        'is-fixed': fixed,
        'is-spacy': spacy
      })

    return (
      <div className={rootClasses}>
        <div className='react-spinner'>
          {bars}
        </div>
        {this.props.children
          ? <span className='react-spinner-text'>{this.props.children}</span>
          : null
        }
      </div>
    )
  }
}
