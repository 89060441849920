import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Drawer from '../Drawer'

const DialogHOC = (ComposedComponent) => class extends Component {
  static displayName = 'DialogHOC'

  static propTypes = {
    title: PropTypes.string,
    router: PropTypes.object
  }

  onClose = () => {
    this.props.router.push({ pathname: '/app' })
  }

  render () {
    const { title } = this.props

    return (
      <Drawer
        onClose={this.onClose}
        className='Modal is-narrow'
        title={title}>

        {({ onClose }) => (
          <ComposedComponent
            {...this.props}
            onClose={onClose}
          />
        )}

      </Drawer>
    )
  }
}

export default DialogHOC
