import React from 'react'
import { FormField } from 'react-form'

export default function ListField ({ field, options }) {
  const listOptions = React.useMemo(() => {
    return options.map(opt => (
      <option key={opt.value} value={opt.value}>
        {opt.label}
      </option>
    ))
  }, [options])

  function handleChange (setValue) {
    return (evt) => {
      const { value } = evt.target
      const valueExists = options.some(opt => opt.value === value)
      setValue(valueExists ? value : null)
    }
  }

  return (
    <FormField field={field}>
      {({ setValue, getValue, setTouched, ...other }) => {
        return (
          <div>
            <input
              id={field}
              list={`${field}-list`}
              value={getValue()}
              onChange={handleChange(setValue)}
            />
            <label htmlFor={field} />

            <datalist id={`${field}-list`} >
              {listOptions}
            </datalist>
          </div>
        )
      }}
    </FormField>
  )
}
