import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

require('./Percentage.css')

export default function Percentage ({ value, withSign = true, boxed = true, decimals = 0 })
{
  if (!isFinite(value)) return null

  const formatter = new Intl.NumberFormat('da-DK',
  {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals
  })

  const classes = classNames(
    'Percentage',
    {
      'with-sign': withSign,
      'is-boxed': boxed,
      [`is-${value > 0 ? 'positive' : 'negative'}`]: value !== 0
    })

  const number = formatter.format(Math.abs(value))

  return <span className={classes}>{number}</span>
}

Percentage.propTypes =
{
  value: PropTypes.number.isRequired,
  withSign: PropTypes.bool,
  boxed: PropTypes.bool,
  decimals: PropTypes.number
}
