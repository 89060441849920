import React from 'react'
import { connect } from 'react-redux'

import Money from './Money'
import Percentage from './Percentage'
import { diff } from '../utils'
import {
  getTotalInvestment,
  getTotalValue,
  getTotalProfit,
  getDisplayCurrency
} from '../selectors'

function InvestmentSummary ({
  totalInvestment,
  totalValue,
  totalProfit,
  displayCurrency
}) {
  const profitChange = diff(totalInvestment, totalValue)

  return (
    <section>
      <div className='InvestmentSummary is-profit'>
        <span className='label'>Total profit</span>
        <div className='content'>
          <Money
            withSign
            withColors
            amount={displayCurrency.toLocal(totalProfit)}
            currency={displayCurrency.code} />
          {profitChange !== 0 && (
            <Percentage value={profitChange} />
          )}
        </div>
      </div>
      <div className='InvestmentSummary'>
        <span className='label'>Total investment</span>
        <Money amount={displayCurrency.toLocal(totalInvestment)} currency={displayCurrency.code} />
      </div>
      <div className='InvestmentSummary'>
        <span className='label'>Total net worth</span>
        <Money amount={displayCurrency.toLocal(totalValue)} currency={displayCurrency.code} />
      </div>
    </section>
  )
}

const mapStateToProps = (state, props) => ({
  totalInvestment: getTotalInvestment(null)(state),
  totalValue: getTotalValue(null)(state),
  totalProfit: getTotalProfit(null)(state),
  displayCurrency: getDisplayCurrency(state)
})

export default connect(mapStateToProps)(InvestmentSummary)
