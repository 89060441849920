import React from 'react'

import LoginFormContainer from '../components/LoginForm'
import SocialLoginButtons from '../components/SocialLoginButtons'

export default class LoginPage extends React.Component {
  onSuccess = (user) => {
    this.props.router.push({ pathname: '/app' })
  }

  render () {
    return (
      <div className='narrow-container'>
        <h1 className='page-title'>Log in to Coinero</h1>

        <SocialLoginButtons onSuccess={this.onSuccess} />

        <h2 className='email-form-header'>Login with email</h2>
        <LoginFormContainer {...this.props} onSuccess={this.onSuccess} />
      </div>
    )
  }
}
