
export default function settings (state = null, action) {
  const { type, payload } = action

  switch (type) {
    case 'SETTINGS_UPDATE': {
      return payload || { currency: 'USD' }
    }

    default:
      return state
  }
}
