import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Text } from 'react-form'

import { auth } from '../lib/firebase'

export default class LoginFormContainer extends Component {
  static defaultProps = {
    onError: () => {},
    onSuccess: () => {}
  }

  static propTypes = {
    onError: PropTypes.func,
    onSuccess: PropTypes.func
  }

  state =
  {
  }

  onSubmit (values)
  {
    auth
      .signInWithEmailAndPassword(values.email, values.password)
      .then(user =>
      {
        this.props.onSuccess(user)
      })
      .catch(err =>
      {
        console.log('Login error:', err)

        this.props.onError(err.code)

        let errorCode = err.code
        let errorMessage = err.message

        if (errorCode === 'auth/invalid-email' ||
          errorCode === 'auth/wrong-password' ||
          errorCode === 'auth/user-not-found')
        {
          this.setState({ error: 'Wrong email/password.' })
        }
        else
        {
          this.setState({ error: errorMessage })
        }
      })
  }

  render ()
  {
    return (
      <LoginForm error={this.state.error} onSubmit={values => this.onSubmit(values)} />
    )
  }
}

export class LoginForm extends Component
{
  static propTypes =
  {
    onSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  }

  render ()
  {
    return (
      <Form onSubmit={values => this.props.onSubmit(values)}>
      {({ submitForm, values }) =>
      {
        return (
          <form className='LoginForm' onSubmit={submitForm}>

            {this.props.error && (
              <div className='Alert is-error' role='alert'>
                <strong>Login failed: </strong>
                {this.props.error}
              </div>
            )}

            <div className='form-group'>
              <label>Email</label>
              <Text
                field='email'
                type='email'
                className='form-control'
                autoComplete='username email'
                placeholder='eg satoshi@bitcoin.org'
                required
              />
            </div>

            <div className='form-group'>
              <label>Password</label>
              <Text
                field='password'
                type='password'
                className='form-control'
                autoComplete='current-password'
                placeholder='••••••'
                required
                minLength='6'
              />
            </div>

            <button type='submit' className='button'>Log in</button>

          </form>
        )
      }}
      </Form>
    )
  }
}
