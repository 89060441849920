import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import styled from 'styled-components'

// import firebase from 'firebase/app'
// import 'firebase/firestore'

import Header from '../layout/Header'
import Loader from '../components/Loader'
import EmptyState from '../components/EmptyState'
import TitleSummary from '../components/TitleSummary'
import CoinInvestments from '../components/CoinInvestments'
import { database, uid, logOut } from '../lib/firebase'
import { isAppLoading, getSortedInvestmentsByCoin } from '../selectors'

const Footer = styled.footer`
  margin: 2rem 0;
  display: grid;
  align-items: center;
  justify-content: center;
`

function InvestmentsList ({ investments }) {
  if (!investments) {
    return null
  }

  return (
    <section className='Investments'>
      {Array.from(investments).map(([coinSymbol, investments]) => {
        return (
          <div key={coinSymbol}>
            <CoinInvestments coin={coinSymbol} />
          </div>
        )
      })}
    </section>
  )
}

class AppPage extends Component {
  constructor (props) {
    super(props)

    this.state = {}

    this.assetsRef = database.ref(`/assets/${uid()}`)
    this.settingsRef = database.ref(`/settings/${uid()}`)
    this.coinPricesRef = database.ref('/coin_prices')
    // this.coinPrices2Ref = firestore.collection('coins')
    this.ratesRef = database.ref('/rates')

    window.__debug = () => {
      this.assetsRef.once('value', snapshot => {
        const data = {
          uid: uid(),
          investments: snapshot.val()
        }
        window.open(
          'about:blank'
        ).document.body.innerHTML = `<pre>${JSON.stringify(
          data,
          null,
          4
        )}</pre>`
      })
    }
  }

  static propTypes = {
    prices: PropTypes.object,
    assets: PropTypes.array
  }

  showFeedbackForm = () => {
    window.typeformEmbed.makePopup(
      'https://coinero.typeform.com/to/N6ROmo?uid=' + uid(),
      {
        mode: 'drawer_right',
        autoOpen: true,
        hideFooter: true,
        hideHeaders: true
        // onSubmit: () => {}
      }
    )

    document.querySelector(`iframe[src*='typeform.com']`).contentWindow.focus()
  }

  addInvestment = () => {
    this.props.router.push(`/app/asset`)
  }

  editInvestment = id => {
    this.props.router.push(`/app/asset?id=${id}`)
  }

  componentDidMount () {
    const { dispatch } = this.props

    this.coinPricesRef.orderByChild('rank').on(
      'value',
      snapshot => {
        const coins = new Map()

        snapshot.forEach(c => {
          coins.set(c.key, c.val())
        })

        dispatch({ type: 'COINS_UPDATE', payload: coins })
      },
      err => console.error(err)
    )

    // this.coinPrices2Ref.get()
    //   .then(snapshot => {
    //     const coins = new Map()

    //     snapshot.forEach(c => {
    //       coins.set(c.id, c.data())
    //     })

    //     console.log({ coins })
    //     dispatch({ type: 'COIN_UPDATE', payload: coins })
    //   })
    //   .catch(err => {
    //     console.error('Failed to read coin prices:', err)
    //   })


    this.ratesRef.on(
      'value',
      snapshot => {
        dispatch({ type: 'RATES_UPDATE', payload: snapshot.val() })
      },
      err => console.error(err)
    )

    this.settingsRef.on(
      'value',
      snapshot => {
        dispatch({ type: 'SETTINGS_UPDATE', payload: snapshot.val() })
      },
      err => console.error(err)
    )

    this.assetsRef
      .orderByChild('purchased')
      .on(
        'value',
        snapshot => {
          let assets = []

          snapshot.forEach(childSnapshot => {
            const val = childSnapshot.val()

            assets.push({
              ...val,
              id: childSnapshot.key
            })
          })

          // skip faulty assets for now
          assets = assets.filter(a => {
            if (a.error || a.ready === false) return false
            return true
          })

          assets = assets.map(a => {
            if (a.price === undefined) a.price = null
            return a
          })

          dispatch({ type: 'ASSETS_UPDATE', payload: assets })
        },
        err => console.error(err)
      )
  }

  componentWillUnmount () {
    this.assetsRef.off()
    this.settingsRef.off()
    this.coinPricesRef.off()
    this.ratesRef.off()

    delete window.__debug
  }

  logOut = () => {
    this.props.router.replace('/')
    logOut()
    this.props.dispatch({ type: 'LOGOUT' })
  }

  render () {
    const { assets, isAppLoading, investmentsByCoin } = this.props

    if (isAppLoading) {
      return <Loader fixed>Loading...</Loader>
    }

    return (
      <div>
        <Header onLogout={this.logOut} />

        <TitleSummary />

        {assets.length === 0 && <EmptyState onClick={this.addInvestment} />}

        <InvestmentsList investments={investmentsByCoin} />

        {this.props.children}

        <Footer>
          <Link to='/app/settings'>Customize</Link>
        </Footer>

        <a className='FeedbackLink' onClick={this.showFeedbackForm}>
          Feedback 💬
        </a>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isAppLoading: isAppLoading(state),
  currency: state.currency,
  rates: state.rates,
  assets: state.assets,
  investmentsByCoin: getSortedInvestmentsByCoin(state)
})

export default connect(mapStateToProps)(AppPage)
