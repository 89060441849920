import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
`

const Button = styled.span`
  user-select: none;
  padding: 0 5px;
`

const List = styled.div`
  z-index: 1000;
  min-width: 200px;
  position: absolute;
  top: calc(100% + 15px);
  right: -10px;
  background: #fff;
  border-radius: 4px;
  padding: 8px;
  pointer-events: none;
  transform: translateY(-5px);
  transform-origin: 0 0;
  opacity: 0;
  will-change: transform, opacity;
  transition-property: transform, opacity;
  transition-duration: 300ms, 200ms;
  transition-timing-function: ease-in-out;
  filter:
    drop-shadow(rgba(0,0,0,0.2) 0px 0px 1px)
    drop-shadow(rgba(0,0,0,0.1) 0px 4px 2px);

  &::after {
    position: absolute;
    right: 20px;
    bottom: 100%;
    margin-left: -10px;
    content: '';
    border: 10px solid;
    border-color: transparent transparent white;
  }

  ${props => props.open && `
    transform: none;
    opacity: 1;
    pointer-events: auto;
  `}
`

const ListItem = styled.div`
  display: block;
  cursor: pointer;
  border-radius: 5px;
  transition: background .2s;

  &:hover {
    background: #EFEFEF;
  }

  &:active {
    background: rgb(220, 220, 220);
  }

  a:hover {
    text-decoration: none;
  }

  > * {
    display: block;
    padding: 12px 15px;
  }
`

class Menu extends React.Component {
  rootRef = null
  buttonRef = null

  state = {
    open: false
  }

  toggleMenu = () => {
    if (!this.state.open)
      document.addEventListener('click', this.onDocumentClick, true)
    else
      document.removeEventListener('click', this.onDocumentClick, true)

    this.setState(prevState => ({ open: !prevState.open }))
  }

  onDocumentClick = (evt) => {
    if (this.buttonRef !== evt.currentTarget && !this.buttonRef.contains(evt.target))
      this.toggleMenu()
  }

  wrapChild = (child) => {
    if (child.type === MenuItem) return child
    return <ListItem>{child}</ListItem>
  }

  render () {
    const {children} = this.props
    const {open} = this.state

    return (
      <Container innerRef={ref => { this.rootRef = ref }}>
        <Button innerRef={ref => { this.buttonRef = ref }} onClick={this.toggleMenu}>
          {this.props.button}
        </Button>
        <List open={open}>
          {React.Children.map(children, this.wrapChild)}
        </List>
      </Container>
    )
  }
}

const MenuItem = ({children}) => <ListItem>{children}</ListItem>

Menu.Item = MenuItem

export default Menu
