
import { symbols } from '../const/currencies'

export function getFloatOrNull (value)
{
  if (value === undefined || value === null) return null

  value = value.toString().replace(',', '.')

  let parsedValue = parseFloat(value)

  if (isNaN(parsedValue)) return null

  return parsedValue
}


/*
* amount: number
* isoCode: EUR (3 letter currency code)
*/
export function displayMoney (amount, currencyIsoCode = 'EUR', prefixWithSign = false)
{
  currencyIsoCode = currencyIsoCode.toUpperCase()

  const formatter = new Intl.NumberFormat('da-DK',
  {
    // style: 'currency',
    // currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  const symbol = symbols[currencyIsoCode] || currencyIsoCode
  // const sign = prefixWithSign ? (amount === 0 ? '' : (amount > 0 ? '+' : '')) : ''

  let formattedNumber = formatter.format(amount)

  // if (!prefixWithSign && formattedNumber < 0) formattedNumber = formattedNumber.replace('-', '')

  return `${formattedNumber} ${symbol}`
}

export const applyRate = (amount, rate) => amount * rate

export const sign = (number) => number === 0 ? '' : (number > 0 ? '+' : '-')

export const percentageDiff = (oldNumber, newNumber) =>
  parseFloat((((newNumber - oldNumber) / oldNumber) * 100).toFixed(2))

export const diff = (oldNumber, newNumber) => {

  if (oldNumber === 0 && newNumber === 0) return 0

  const number = ((newNumber - oldNumber) / oldNumber) * 100

  if (number !== 0) return number / 100

  return number
}

export const displayNumber = (number, decimals = 2) => {

  const formatter = new Intl.NumberFormat('de-DE',
  {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals
  })

  return formatter.format(number)
}

export const displayNumberSmart = (number) =>
{
  if (isNaN(number) || typeof number !== 'number')
    throw new Error('displayNumberSmart: invalid number: ' + number)

  const formatter = new Intl.NumberFormat('de-DE',
  {
    minimumFractionDigits: 2,
    maximumFractionDigits: 10
  })

  const formatted = formatter.format(number)
  let [integers, decimals] = formatted.split(',')

  if (number >= 100)
  {
    return integers
  }
  else if (number > 1)
  {
    decimals = decimals.substring(0, 2)

    if (parseInt(decimals, 10) === 0)
      return integers

    return integers + ',' + decimals
  }
  else
  {
    const firstNonZeroIndex = decimals.split('').findIndex(decimal => decimal !== '0')
    decimals = decimals.substring(0, Math.max(2, firstNonZeroIndex + 1))

    if (parseInt(decimals, 10) === 0) return integers
    return integers + ',' + decimals
  }
}

export const displayPercentage = (number, { decimals = 0, withSign = false }) => {
  const formatter = new Intl.NumberFormat('da-DK',
  {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals
  })

  const percentage = formatter.format(Math.abs(number)).replace(/\s%/, '%')
  return (withSign ? (number > 0  ? '+' : '-') : '') + percentage
}

export function snapshotToArray (keyPropName = 'key')
{
  return (snapshot) =>
  {
    let result = []
    snapshot.forEach(child =>
    {
      const val = child.val()
      val[keyPropName] = child.key
      result.push(val)
    })
    return result
  }
}

export function objToArray (obj, keyProp, valueProp) {
  return Object.keys(obj).map(k => ({
    [keyProp]: k,
    [valueProp]: obj[k]
  }))
}

export function openPopup (url, height = 620, width = 800)
{
  const left = (window.screen.width / 2) - (width / 2)
  const top = (window.screen.height / 2) - (height / 2)
  const windowFeatures = `width=${width}, height=${height}, top=${top}, left=${left}`

  window.open(url, '_blank', windowFeatures)
}
