import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { symbols } from '../const/currencies'

import './Money.css'

const Money = ({
  amount,
  currency,
  sign,
  className,
  withSymbol = true,
  withColors = false,
  decimals = 0
}) => {
  currency = currency.toUpperCase()

  const formatter = new Intl.NumberFormat('da-DK', {
    // style: 'currency',
    // currency: 'EUR',
    // currencyDisplay: 'symbol',
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals
  })

  const classes = classNames(
    'Money',
    className,
    {
      'with-symbol': withSymbol,
      'with-colors': withColors,
      [`is-${amount > 0 ? 'positive' : 'negative'}`]: amount !== 0
    })

  // amount = Math.abs(amount) // to prevent the minus sign.

  const number = formatter.format(amount)

  let symbol = symbols[currency] || currency

  return (
    <span className={classes} data-symbol={symbol}>
      {number}
    </span>
  )
}

Money.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  sign: PropTypes.bool,
  className: PropTypes.string,
  decimals: PropTypes.number
}

export default Money
