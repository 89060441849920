import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Text } from 'react-form'

import { auth } from '../lib/firebase'

export default class SignupFormContainer extends Component
{
  static defaultProps =
  {
    onError: () => {},
    onSuccess: () => {}
  }

  static propTypes =
  {
    onError: PropTypes.func,
    onSuccess: PropTypes.func
  }

  state = {}

  onSubmit (values)
  {
    const { email, password, display_name } = values

    auth
      .createUserWithEmailAndPassword(email, password)
      .then(user =>
      {
        console.log('Created user', user)

        Promise.all(
        [
          user.updateProfile({ displayName: display_name }),
        ])
        .then(() =>
        {
          console.log('Updated user details')
          this.props.onSuccess(user)
        })
        .catch(err =>
        {
          this.props.onError(err)
          console.error('Error while updating user details', err)
        })
      })
      .catch(err =>
      {
        console.log('Signup error:', err)
        this.props.onError(err.code)

        // Handle Errors here.
        let errorCode = err.code
        let errorMessage = err.message

        if (errorCode === 'auth/weak-password')
        {
          this.setState({ error: 'The password is too weak.' })
        }
        else
        {
          this.setState({ error: errorMessage })
        }
      })
  }

  render ()
  {
    return (
      <SignupForm
        error={this.state.error}
        onSubmit={values => this.onSubmit(values)} />
    )
  }
}

export class SignupForm extends Component
{
  static propTypes =
  {
    onSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  }

  onSubmit (values)
  {
    console.log('onSubmit', values)
    this.props.onSubmit(values)
  }

  onValidate (values)
  {
    const { display_name, password, password_again } = values

    return {
      display_name: !display_name ? 'A name is required' : null,
      password_again: (password !== password_again) ? 'Passwords does not match' : null
    }
  }

  render ()
  {
    return (
      <Form onSubmit={values => this.onSubmit(values)} validate={this.onValidate}>
      {({ submitForm, values }) =>
      {
        return (
          <form className='SignupForm' onSubmit={submitForm}>

            {this.props.error && (
              <div className='Alert is-error' role='alert'>
                <strong>Sign up failed: </strong>
                {this.props.error}
              </div>
            )}

            <div className='form-group'>
              <label>Name</label>
              <Text field='display_name' type='text' className='form-control' autoComplete='name' required minLength='2'
              />
            </div>

            <div className='form-group'>
              <label>Email</label>
              <Text field='email' className='form-control' type='email' autoComplete='username email' required />
            </div>

            <div className='form-group'>
              <label>Password</label>
              <Text
                field='password'
                className='form-control'
                type='password'
                autoComplete='new-password'
                required
                minLength='6'
              />
            </div>

            <div className='form-group'>
              <label>Password again</label>
              <Text
                field='password_again'
                className='form-control'
                type='password'
                autoComplete='new-password'
                required
                minLength='6'
              />
            </div>

            <button type='submit' className='button'>Sign up</button>

          </form>
        )
      }}
      </Form>
    )
  }
}
