import React, { Component } from 'react'

import Loader from './Loader'
import AssetForm from './AssetForm'
import { database, uid } from '../lib/firebase'
import { getFloatOrNull } from '../utils'

export default class AssetFormContainer extends Component {
  constructor (props) {
    super(props)

    this.editId = props.location.query.id
    this.coin = props.location.query.coin
    this.isEdit = !!this.editId
    this.isNew = !this.isEdit

    this.state = {
      values: this.isEdit ? null : { coin: this.coin }
    }

    if (this.isEdit) {
      database.ref(`/assets/${uid()}/${this.editId}`)
        .once('value')
        .then(res => {
          const values = res.val() || {}
          this.setState({ values })
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  onSave = (formValues) => {
    const key = this.isEdit
      ? this.editId
      : database.ref().child('assets').push().key

    let amount = getFloatOrNull(formValues.amount)
    let price = getFloatOrNull(formValues.price)

    // if (formValues.price_type === 'sum')
    //   price = price / amount

    const postData = {
      uid: uid(),
      coin: formValues.coin,
      amount,
      price,
      currency: formValues.currency,
      purchased: formValues.purchased,
      notes: formValues.notes || null
    }

    if (this.isNew) {
      postData.created = new Date().toISOString()
    }

    // if (this.isNew) postData.ready = false

    console.log('Post data\n', JSON.stringify(postData, null, 2))

    database.ref(`/assets/${uid()}/${key}`)
      .update(postData)
      .then(res => {
        this.onClose()
      })
      .catch(err => {
        console.error(err)
        alert('Failed to save investment.')
      })
  }

  onDelete = () => {
    database.ref(`assets/${uid()}/${this.editId}`)
      .remove()
      .then(res => {
        this.onClose()
      })
      .catch(err => {
        console.error(err)
        alert('Failed to delete investment.')
      })
  }

  onClose = () => {
    this.props.router.replace({ pathname: '/app' })
  }

  render () {
    return (
      // <Drawer
      //   onClose={this.onClose}
      //   className='Modal is-narrow'
      //   title={(this.isEdit ? 'Edit ' : 'New ') + 'investment'}
      // >
      <div>
        {this.state.values === null && <Loader spacy />}

        {this.state.values &&
        (
          <AssetForm
            initialValues={this.state.values}
            isEdit={this.isEdit}
            onSave={this.onSave} />
        )}
      </div>
      // </Drawer>
    )
  }
}
