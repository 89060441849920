import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Text, Select, Textarea } from 'react-form'

// import SelectField from './fields/SelectField'
import ListField from './fields/ListField'
import { getFloatOrNull, displayNumberSmart } from '../utils/utils'
import { getCoinPrices } from '../selectors'

const ERROR_COLOR = '#D2382B'

const currenciesOptions =
[
  { label: 'USD', value: 'USD' },
  { label: 'EURO', value: 'EUR' },
  { label: 'BTC', value: 'BTC' }
]

class AssetForm extends Component {
  constructor (props) {
    super(props)

    this.coinsOptions = []

    for (const [symbol, coin] of props.coinPrices.entries()) {
      this.coinsOptions.push({
        // label: `${coin.title} (<strong>${symbol}</strong>)`,
        label: `${coin.title} (${symbol})`,
        value: symbol
      })
    }

    this.state = {
      initialValues: {
        currency: 'USD',
        ...props.initialValues
      }
    }
  }

  static propTypes = {
    initialValues: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    isEdit: PropTypes.bool.isRequired
  }

  onSubmit (values) {
    console.log('Form submit:', values)
    this.props.onSave(values)
  }

  isValidPurchaseDate = (value) => {
    if (!value) return false

    let asDate = new Date(value)

    if (isNaN(asDate.getTime())) return false
    if (asDate.getFullYear() < 2000) return false

    return true
  }

  onValidate = ({ coin, amount, price, purchased }) => {
    const hasPrice = price !== '' && price !== undefined

    return {
      coin: !coin ? '*' : null,
      amount: getFloatOrNull(amount) === null ? '*' : null,
      price: !hasPrice ? null : (getFloatOrNull(price) === null ? '*' : null),
      purchased: !this.isValidPurchaseDate(purchased) ? '*' : null
    }
  }

  render () {
    const { initialValues } = this.state
    const { isEdit } = this.props

    return (
      <Form
        onSubmit={values => this.onSubmit(values)}
        validate={this.onValidate}
        defaultValues={initialValues}
      >
        {({ submitForm, values, getError }) => {
          let pricePerCoin = null
          // let totalPrice = null
          let price = getFloatOrNull(values.price)
          let amount = getFloatOrNull(values.amount)

          if (price !== null && amount !== null) pricePerCoin = price / amount
          else pricePerCoin = null

          return (
            <form className='AssetForm Modal-content' onSubmit={submitForm}>

              <div className='FormRow'>
                <label style={{ color: getError('coin') ? ERROR_COLOR : '' }}>
                  Coin *
                </label>
                <ListField
                  field='coin'
                  options={this.coinsOptions}
                  placeholder='Select coin'
                  noItemsFound='No coins found'
                />
              </div>

              <div className='FormRow'>
                <label style={{ color: getError('amount') ? ERROR_COLOR : '' }}>
                  Amount *
                </label>
                <Text field='amount' type='number' pattern='\\d*' placeholder='E.g. 20.99' showErrors={false} />
              </div>

              <div className='FormRow'>
                <label style={{ color: getError('price') ? ERROR_COLOR : '' }}>
                  Total Price
                </label>

                <div className='InputWithSelect'>
                  <Text field='price' type='number' pattern='\\d*' placeholder='E.g. 0.25' showErrors={false} />
                  <Select field='currency' options={currenciesOptions} showErrors={false} />
                </div>

                {pricePerCoin !== null &&
                  <p className='PricePerCoin'>
                    <span>Price per coin: </span>
                    {displayNumberSmart(pricePerCoin)} {values.currency}
                  </p>
                }
              </div>

              <div className='FormRow'>
                <label style={{ color: getError('purchased') ? ERROR_COLOR : '' }}>
                  Purchased *
                </label>
                <Text field='purchased' type='date' placeholder='YYYY-MM-DD' showErrors={false} />
              </div>

              <div className='FormRow'>
                <label>Notes</label>
                <Textarea field='notes' />
              </div>

              <footer className='Modal-footer'>
                <button className='Button' type='submit'>
                  {`${isEdit ? 'Update' : 'Create'} investment`}
                </button>
              </footer>

            </form>
          )
        }}
      </Form>
    )
  }
}

const mapStateToProps = (state, props) => ({
  coinPrices: getCoinPrices(state)
})

export default connect(
  mapStateToProps
)(AssetForm)
