
export default function user (state = {}, action) {
  const { type, payload } = action

  switch (type) {
    case 'USER_STATE_CHANGE':
      return payload

    default:
      return state
  }
}
