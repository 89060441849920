import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Route, IndexRoute, browserHistory } from 'react-router'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { syncHistoryWithStore } from 'react-router-redux'

import { auth } from './lib/firebase'
import ImportOrders from './containers/ImportOrders'
import App from './App'
import ContentPage from './pages/ContentPage'
import AppPage from './pages/AppPage'
import FrontPage from './pages/FrontPage'
import LoginPage from './pages/LoginPage'
import SignupPage from './pages/SignupPage'
import AuthCallbackPage from './pages/AuthCallbackPage'
import DialogHOC from './components/hoc/DialogHOC'
import AssetFormContainer from './components/AssetFormContainer'
import InvestmentsModal from './components/InvestmentsModal'
import SettingsView from './views/SettingsView'
import reducers from './reducers'
import './index.css'

// const mockState = {
//   assets: [
//     {
//       id: 'a1',
//       coin: 'BTC',
//       amount: 1.23,
//       price: 1000,
//       currency: 'USD',
//       purchased: new Date(2019, 0, 0)
//     },
//     {
//       id: 'a2',
//       coin: 'ETH',
//       amount: 42.24,
//       price: 1000,
//       currency: 'USD',
//       purchased: new Date(2019, 0, 0)
//     }
//   ],
//   coinPrices: new Map([
//     [
//       'BTC',
//       {
//         title: 'Bitcoin',
//         USD: 10500
//       }
//     ],
//     [
//       'ETH',
//       {
//         title: 'Ethereum',
//         USD: 320
//       }
//     ]
//   ]),
//   rates: {
//     USD: 1,
//     EUR: 1
//   },
//   settings: {
//     currency: 'EUR',
//     coinOrderField: 'name',
//     coinOrderDirection: -1,
//     sync: {
//     }
//   }
// }

const initialState = {
  // ...mockState
}

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// composeEnhancers( applyMiddleware(logger) )
const store = createStore(
  reducers,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

const history = syncHistoryWithStore(browserHistory, store)

/*
function PrivateRoute ({ component: Component, authenticated, ...rest })
{
  return (
    <Route
      {...rest}
      render={(props) => authenticated === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
    />
  )
}
*/

function createElement (Component, props) {
  const { route } = props

  if (route.public) {
    return (
      <ContentPage className={route.className}>
        <Component {...props} />
      </ContentPage>
    )
  }

  if (route.dialog) {
    const DialogComponent = DialogHOC(Component)
    const title = typeof route.dialog === 'string' ? route.dialog : null

    return <DialogComponent title={title} {...props} />
  }

  return <Component {...props} />
}

function requireAuth (nextState, replace, callback) {
  const removeListener = auth.onAuthStateChanged(user => {
    if (!user) {
      replace({ pathname: '/', state: { nextPathname: nextState.location.pathname } })
    }

    removeListener()
    callback()
  })
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history} createElement={createElement}>
      <Route path='/' component={App}>

        <IndexRoute component={FrontPage} public className='FrontPage' />
        <Route path='login' component={LoginPage} public className='LoginPage' />
        <Route path='signup' component={SignupPage} public className='SignupPage' />

        <Route path='app/auth/:provider' component={AuthCallbackPage} />

        <Route path='app' component={AppPage} onEnter={requireAuth}>
          <Route path='asset' component={AssetFormContainer} dialog />
          <Route path='settings' component={SettingsView} dialog='Settings' />
          <Route path='import' component={ImportOrders} dialog='Import orders' />
          <Route path='investments/:coin' component={InvestmentsModal} dialog />
        </Route>

      </Route>
    </Router>
  </Provider>
  ,
  document.getElementById('root')
)
