import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin: 1rem;
  height: 100%;
  min-height: 50vh;
`

const Logo = styled.div`
  font-size: 100px;
`

const EmptyState = ({ onClick }) =>
(
  <Container>
    <Logo>💸</Logo>
    <h2>Lets get started</h2>
    <p>
      Add your first investment to get started
    </p>
    <button className='Button' onClick={onClick}>Add investment</button>
  </Container>
)

EmptyState.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default EmptyState