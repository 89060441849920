import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import styled from 'styled-components'

import Overlay from './Overlay'
import FirstChild from './FirstChild'

const DrawerBody = styled.article`
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1100;
  background: white;
  box-shadow: 0 0px 10px 10px rgba(0, 0, 0, 0.08);
  will-change: transform, opacity;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 600px) {
    width: 45vw;
    max-width: 800px;
    min-width: 500px;
  }
`

const CLOSE_TIMEOUT = 350

const SlideAnimation = ({ children }) =>
  <ReactCSSTransitionGroup
    component={FirstChild}
    transitionAppear
    transitionAppearTimeout={450}
    transitionEnterTimeout={450}
    transitionLeaveTimeout={CLOSE_TIMEOUT}
    transitionName={{
      appear: 'SlideIn',
      appearActive: 'SlideIn_active',
      leave: 'SlideOut',
      leaveActive: 'SlideOut_active'
    }}
  >
    {children}
  </ReactCSSTransitionGroup>

SlideAnimation.propTypes = {
  children: PropTypes.node
}

class Drawer extends Component {
  constructor () {
    super()
    this.state = {
      isClosing: false
    }
  }

  onClose = () => {
    this.setState({ isClosing: true })
    setTimeout(() => {
      this.props.onClose()
    }, CLOSE_TIMEOUT + 100)
  }

  render () {
    const { children } = this.props
    const { isClosing } = this.state
    const body = typeof children === 'function'
      ? children({ onClose: this.onClose })
      : children

    return (
      <div>
        <SlideAnimation>
          {!isClosing && (
            <DrawerBody>

              <div className='Modal-body'>

                <div className='Modal-header'>
                  <span>{this.props.title}</span>
                  <a className='Modal-close' onClick={this.onClose}>&times;</a>
                </div>

                {body}

              </div>

            </DrawerBody>
          )}
        </SlideAnimation>
        <Overlay visible={!isClosing} onClick={this.onClose} />
      </div>
    )
  }
}

Drawer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onClose: PropTypes.func
}

Drawer.defaultProps = {
  onClose: () => {}
}

export default Drawer
