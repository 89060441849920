import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledButton = styled.a`
  display: inline-block;
  cursor: pointer;
  padding: 2px 6px;
  font-weight: 600;
  border-radius: 3px;
  font-size: 16px;
  color: #7a7a7a;
  z-index: 100;

  &:hover {
    background: #efefef;
    text-decoration: none;
  }
`
const MoreButton = ({ text = '...' }) => (
  <StyledButton>{text}</StyledButton>
)

MoreButton.propTypes = {
  text: PropTypes.string
}

export default MoreButton
