import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { uid, database } from '../lib/firebase'
import { getInvestments, getSyncSettings } from '../selectors'

import KeysExchangeIntegration from '../components/integrations/KeysIntegration'
import OAuthExchangeIntegration from '../components/integrations/OAuthIntegration'
import WalletIntegration from '../components/integrations/WalletIntegration'

class ImportOrders extends Component {
  constructor (props) {
    super(props)
    this.state = {
      integrations: null
    }
  }

  static propTypes = {
    investments: PropTypes.array,
    syncSettings: PropTypes.object
  }

  componentWillMount () {
    // this.settingsRef = database.ref(`/settings/${uid()}/sync`)

    // this.settingsRef.on('value', (snapshot) => {
    //   const integrations = snapshot.val() || {}

    //   this.setState({ integrations })
    // })
  }

  componentWillUnmount () {
    // this.settingsRef.off()
  }

  isTransactionImported = (ref) => {
    this.props.investments.some(i => i.ref === ref)
  }

  render () {
    const { syncSettings } = this.props

    if (!syncSettings) return null

    // const getInvestmentsWithRef = (provider) => this.props.investments
    //   .filter(i => i.ref && i.ref.startsWith(provider + '/'))
    //   .map(i => i.ref)

    return (
      <div>

        <OAuthExchangeIntegration
          provider='coinbase'
          title='Coinbase'
          isTransactionImported={this.isTransactionImported}
          integration={syncSettings.coinbase}
          popupSize={[800, 800]}
        />

        <hr />

        <KeysExchangeIntegration
          provider='bittrex'
          title='Bittrex'
          subtitle='Note: Only orders from one month ago are shown (Bittrex limitation).'
          isTransactionImported={this.isTransactionImported}
          integration={syncSettings.bittrex}
        />

        <hr />

        <WalletIntegration
          isTransactionImported={this.isTransactionImported}
        />


      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  investments: getInvestments(state),
  syncSettings: getSyncSettings(state)
})

export default connect(mapStateToProps)(ImportOrders)
