import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GridContainer, FlexContainer, Box } from 'breakbox'

import WalletTransactionType from '../../types/wallet-transaction'
import { objToArray } from '../../utils'
import { importTransaction } from './Integration'
import { uid, auth, database, callFunction } from '../../lib/firebase'
import CoinLogo from '../CoinLogo'

export default class WalletIntegration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tokens: []
    }
  }

  static propTypes = {
  }

  addressRef = null

  loadTokens = () => {
    const address = this.addressRef.value
    // const { address } = this.state

    this.setState({ error: null, loading: true })

    return callFunction(`sync/wallet/erc20?address=${address}`)
      .then(response => {
        if (!response.success) {
          this.setState({ loading: false, error: response.error })
          return
        }

        const tokens =
          objToArray(response.tokens, 'coin', 'amount')
          .map(t => ({
            ...t,
            ref: `wallet/${address}`
          }))
          .map(t => WalletTransactionType(t))

        console.log('tokens', tokens)

        this.setState({ tokens, loading: false })
      })
      .catch(err => {
        console.error('Load tokens failed:', err)
        this.setState({ loading: false, error: err })
      })
  }

  renderTokens = () => {
    const { tokens } = this.state
    if (!tokens.length) return null


    return tokens.map(t => {
      const isTransactionImported = this.props.isTransactionImported(t.ref)

      return (
        <GridContainer
          key={t.coin}
          gridGap={1}
          gridTemplateColumns='50px 1fr 1fr'
          padding={1}
        >
          <Box>
            <CoinLogo coin={t.coin} />
            &nbsp;
            {t.coin}
          </Box>
          <Box>
            {t.amount}
          </Box>
          <Box>
            {isTransactionImported ? (
              <button disabled>Imported</button>
            ) : (
              <button onClick={() => importTransaction(t)}>Import</button>
            )}
          </Box>
        </GridContainer>
      )
    })
  }

  render() {
    return (
      <div>

        <input type='text' placeholder='ERC20 address' ref={ref => {this.addressRef = ref}} />
        <button onClick={this.loadTokens}>Load tokens</button>

        {this.renderTokens()}

      </div>
    )
  }
}
